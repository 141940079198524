import React, { Component } from 'react';
import Index from './components/Index';
import Dashboard from './components/Dashboard';
import Legal from './components/Legal';

export default class App extends Component {
  constructor() {
    super();

    this.state = {
      scene: "index"
    };

    this.changeScene = this.changeScene.bind(this);

  }

  changeScene(scene) {
    this.setState({scene});
  }

  render() {
    return (
      <div style={{minHeight: '100vh', background: '#000'}}>

      </div>
    );
  }
}
