import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import WordmarkDark from './../assets/logo-wordmark-black.png';
import { withStyles } from '@material-ui/core/styles';
import CSS from './../css/Styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import firebase from '@firebase/app';
import '@firebase/firestore';
import '@firebase/auth';
import $ from 'jquery'; 


const config = {
    apiKey: "AIzaSyCrg6JDhN6J416IL-pK0ySX7FTdLtJ9c9o",
    authDomain: "eqwityapp.firebaseapp.com",
    databaseURL: "https://eqwityapp.firebaseio.com",
    projectId: "eqwityapp",
    storageBucket: "",
    messagingSenderId: "946828859324",
    appId: "1:946828859324:web:c887f9d2f876c662"
  };

firebase.initializeApp(config);

let DB = firebase.firestore();

class Index extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            message: null,
            scene: "log_in",
            companyName: "",
            industry: "",
            contactPerson: "",
            revenueShare: "",
            website: "",
            email: "",
            phone: "",
            zipCode: "",
            password: "",
            confirmPassword: ""
        }

        this.createRetailer = this.createRetailer.bind(this);
    }

    componentDidMount() {
        $("html, body").scrollTop(0);
    }
    
    changeScene(scene) {
        this.setState({scene});
    }

    authRetailer() {
        if (this.state.scene === "log_in") {
            const AUTH = firebase.auth();
            const EMAIL = this.state.email;
            const PASSWORD = this.state.password;

            AUTH.signInWithEmailAndPassword(EMAIL, PASSWORD).then(() => {
                this.props.changeScene("dashboard");
            }).catch((error) => {
				$("html, body").animate({ scrollTop: 0 }, "400");
                this.setState({message: error.message, loading: false});
                setTimeout(() => {this.setState({message: null})}, 3000)
			})
        } else if (this.state.scene === "registration") {
            this.createRetailer();
        }
    }

	createRetailer() {
        this.setState({loading: true});
		let company_name = this.state.companyName.trim();
        let industry = this.state.industry;
        let contact_person = this.state.contactPerson.trim();
        let revenue_share = this.state.revenueShare;
        let website = this.state.website.trim();
        let email = this.state.email.trim();
        let phone = this.state.phone.trim();
        let zip_code = this.state.zipCode.trim();
		let password = this.state.password.trim();
		let confirm = this.state.confirmPassword.trim();
		if(!company_name || !industry || !contact_person || !revenue_share || !website || !email || !phone || !zip_code || !password || !confirm) {
			$("html, body").animate({ scrollTop: 0 }, "400");
			this.setState({message: "All form fields are required", loading: false});
            setTimeout(() => {this.setState({message: null})}, 3000)
		} else if (password !== confirm) {
			$("html, body").animate({ scrollTop: 0 }, "400");
			this.setState({message: "Your passwords don't match.", loading: false});
			setTimeout(() => {this.setState({message: null})}, 3000)
		} else {
			const AUTH = firebase.auth();
			AUTH.createUserWithEmailAndPassword(email, password).then((userObject) => {
                let user_id = userObject.user.uid;
				this.addRetailerToDb(user_id, company_name, industry, contact_person, revenue_share, website, email, phone, zip_code);
			}).catch((error) => {
				$("html, body").animate({ scrollTop: 0 }, "400");
                this.setState({message: error.message, loading: false});
                setTimeout(() => {this.setState({message: null})}, 3000)
			})
		}
	}

    addRetailerToDb(user_id, company_name, industry, contact_person, revenue_share, website, email, phone, zip_code) {

        DB.collection('retailers').doc(user_id).set({
            user_id: user_id,
            company_name: company_name,
            industry: industry,
            contact_person: contact_person,
            revenue_share: revenue_share,
            website: website,
            email: email,
            phone: phone,
            zip_code: zip_code,
            created_at: firebase.firestore.FieldValue.serverTimestamp()
        }).then(() => {
			$("html, body").animate({ scrollTop: 0 }, "400");
            this.setState({
                message: 'Registration successful!', 
                user_id: user_id,
                company_name: "",
                industry: "",
                contact_person: "",
                revenue_share: "",
                website: "",
                email: "",
                phone: "",
                zip_code: ""
            });
            setTimeout(() => {this.setState({message: null, loading: false})}, 3000)
        }).catch((error) => {
			$("html, body").animate({ scrollTop: 0 }, "400");
            this.setState({message: error.message, loading: false});
            setTimeout(() => {this.setState({message: null})}, 3000)
        })
    }

    render() {
        const { classes } = this.props;
    
        return(
            <div style={{overflow: 'hidden'}}>
                <Snackbar
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                    open={this.state.message !== null}
                    onClose={() => this.setState({message: null})}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.message}</span>}
                />
                <div>
                    <Grid container justify="center" alignItems="center" style={{minHeight: '100vh', textAlign: 'center', overflow: 'hidden'}}>
                        <Grid item md={4}/>
                        <Grid item md={4}>
                            <Paper style={{margin: '2rem 0', padding: '1.5rem'}}>
                            <img alt="Eqwity Logo" src={WordmarkDark} style={{width: '25%', marginBottom: '1rem'}}/>
                            <Typography variant="h5" style={{color: '#000', fontWeight: '100', textAlign: 'center', lineHeight: 1, marginBottom: '0rem'}}>
                                Retailer Dashboard
                            </Typography>
                            <Typography variant="caption" style={{display: 'block', fontWeight: '700', marginTop: '0.25rem', marginBottom: '0.25rem'}}>
                                {this.state.scene === 'log_in' ? 'Log In' : 'Register'}
                            </Typography>
                            <Typography variant="caption" style={{marginBottom: '1rem'}}>
                                {this.state.scene === 'log_in' ? 'Need an account?' : 'Have an account already?'} <span style={{color: 'blue', cursor: 'pointer'}} onClick={() => this.changeScene(`${this.state.scene === 'log_in' ? 'registration' : 'log_in'}`)}>Click here</span>.
                            </Typography>
                            {this.state.loading && <LinearProgress style={{marginBottom: 10}}/>}
                            {this.state.scene === 'registration' && <TextField
                                disabled={this.state.loading}
                                label="Company Name"
                                InputLabelProps={{style: {color: '#000'}}}
                                style={{
                                    width: '100%',
                                    marginTop: 5,
                                    marginBottom: 5,
                                    textAlign: 'left',
                                    backgroundColor: '#fff'
                                }}
                                value={this.state.companyName}
                                variant="outlined"
                                onChange={(event) => this.setState({companyName: event.target.value})}
                            />}
                            {this.state.scene === "registration" && <TextField
                                fullWidth
                                select
                                label="Industry"
                                InputLabelProps={{style: {color: '#000'}}}
                                variant="outlined"
                                className={classes.blockInputWithWhiteBackground}
                                value={this.state.industry}
                                onChange={(event) => this.setState({industry: event.target.value})}
                            >
                                <MenuItem value="advertising">Advertising</MenuItem>
                                <MenuItem value="automotive">Automotive</MenuItem>
                                <MenuItem value="communications">Communications</MenuItem>
                                <MenuItem value="construction">Contruction</MenuItem>
                                <MenuItem value="education">Education</MenuItem>
                                <MenuItem value="entertainment">Entertainment</MenuItem>
                                <MenuItem value="fashion">Fashion</MenuItem>
                                <MenuItem value="food_beverage">Food / Beverage</MenuItem>
                                <MenuItem value="finance_banking">Finance / Banking</MenuItem>
                                <MenuItem value="grocery">Grocery</MenuItem>
                                <MenuItem value="health_fitness">Health & Fitness</MenuItem>
                                <MenuItem value="hospitality">Hospitality</MenuItem>
                                <MenuItem value="insurance">Insurance</MenuItem>
                                <MenuItem value="legal">Legal</MenuItem>
                                <MenuItem value="manufacturing">Manufacturing</MenuItem>
                                <MenuItem value="media">Media</MenuItem>
                                <MenuItem value="medical">Medical</MenuItem>
                                <MenuItem value="outdoors">Outdoors</MenuItem>
                                <MenuItem value="politics">Politics</MenuItem>
                                <MenuItem value="printing_office">Printing / Office Supplies</MenuItem>
                                <MenuItem value="technology">Technology</MenuItem>
                                <MenuItem value="travel_transportation">Travel / Transportation</MenuItem>
                            </TextField>}
                            {this.state.scene === 'registration' && <TextField
                                disabled={this.state.loading}
                                label="Contact Person"
                                InputLabelProps={{style: {color: '#000'}}}
                                style={{
                                    width: '100%',
                                    marginTop: 5,
                                    marginBottom: 5,
                                    textAlign: 'left',
                                    backgroundColor: '#fff'
                                }}
                                value={this.state.contactPerson}
                                variant="outlined"
                                onChange={(event) => this.setState({contactPerson: event.target.value})}
                            />}
                            {this.state.scene === 'registration' && <TextField
                                disabled={this.state.loading}
                                label="Revenue Share %"
                                type="number"
                                InputLabelProps={{style: {color: '#000'}}}
                                style={{
                                    width: '100%',
                                    marginTop: 5,
                                    marginBottom: 5,
                                    textAlign: 'left',
                                    backgroundColor: '#fff'
                                }}
                                helperText="How much of each sale are you willing to give us for sending your business customers?"
                                value={this.state.revenueShare}
                                variant="outlined"
                                onChange={(event) => this.setState({revenueShare: event.target.value})}
                            />}
                            {this.state.scene === 'registration' && <TextField
                                disabled={this.state.loading}
                                label="Website"
                                InputLabelProps={{style: {color: '#000'}}}
                                style={{
                                    width: '100%',
                                    marginTop: 5,
                                    marginBottom: 5,
                                    textAlign: 'left',
                                    backgroundColor: '#fff'
                                }}
                                value={this.state.website}
                                variant="outlined"
                                onChange={(event) => this.setState({website: event.target.value})}
                            />}
                            <TextField
                                disabled={this.state.loading}
                                label="Email"
                                InputLabelProps={{style: {color: '#000'}}}
                                style={{
                                    width: '100%',
                                    marginTop: 5,
                                    marginBottom: 5,
                                    textAlign: 'left',
                                    backgroundColor: '#fff'
                                }}
                                value={this.state.email}
                                variant="outlined"
                                onChange={(event) => this.setState({email: event.target.value})}
                            />
                            {this.state.scene === "registration" && <TextField
                                disabled={this.state.loading}
                                label="Phone"
                                InputLabelProps={{style: {color: '#000'}}}
                                style={{
                                    width: '100%',
                                    marginTop: 5,
                                    marginBottom: 5,
                                    textAlign: 'left',
                                    backgroundColor: '#fff'
                                }}
                                value={this.state.phone}
                                variant="outlined"
                                onChange={(event) => this.setState({phone: event.target.value})}
                            />}
                            {this.state.scene === "registration" && <TextField
                                disabled={this.state.loading}
                                label="Zip Code"
                                InputLabelProps={{style: {color: '#000'}}}
                                style={{
                                    width: '100%',
                                    marginTop: 5,
                                    marginBottom: 5,
                                    textAlign: 'left',
                                    backgroundColor: '#fff'
                                }}
                                value={this.state.zipCode}
                                variant="outlined"
                                onChange={(event) => this.setState({zipCode: event.target.value})}
                            />}
                            <TextField
                                disabled={this.state.loading}
                                label="Password"
                                type="password"
                                InputLabelProps={{
                                    style: {color: '#000', borderColor: '#000'}}}
                                style={{
                                    width: '100%',
                                    marginTop: 5,
                                    marginBottom: 5,
                                    textAlign: 'left',
                                    backgroundColor: '#fff'
                                }}
                                value={this.state.password}
                                variant="outlined"
                                onChange={(event) => this.setState({password: event.target.value})}
                            />
                            {this.state.scene === "registration" && <TextField
                                disabled={this.state.loading}
                                label="Confirm Password"
                                type="password"
                                InputLabelProps={{
                                    style: {color: '#000', borderColor: '#000'}}}
                                style={{
                                    width: '100%',
                                    marginTop: 5,
                                    marginBottom: 5,
                                    textAlign: 'left',
                                    backgroundColor: '#fff'
                                }}
                                value={this.state.confirmPassword}
                                variant="outlined"
                                onChange={(event) => this.setState({confirmPassword: event.target.value})}
                            />}
                            <Button 
                                variant="contained"
                                onClick={() => this.authRetailer()}
                                style={{
                                    backgroundColor: '#000',
                                    width: '100%',
                                    color: '#fff',
                                    marginTop: '0.5rem',
                                    marginBottom: '1rem'
                                }}
                            >
                                {this.state.scene === "log_in" ? 'Log In' : 'Register'}
                            </Button>
                            <Typography variant="caption">
                                By creating an account or logging in, you accept Eqwity's<br/>
                                <span style={{color: 'blue', cursor: 'pointer'}} onClick={() => this.props.changeScene('legal')}>terms of use</span> and <span style={{color: 'blue', cursor: 'pointer'}} onClick={() => this.props.changeScene('legal')}>privacy policy</span>.
                            </Typography>
                            </Paper>
                        </Grid>
                        <Grid item md={4}/>
                    </Grid>
                </div>
            </div>
        );
    }

}

export default withStyles(CSS)(Index);