import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CSS from './../css/Styles';

function DashCard(props) {

    const { classes } = props;

    return(
        <Grid item xs={12} sm={6} md={6}>
            <Card style={{margin: '2rem'}}>
                <CardHeader
                    title={props.title}
                    avatar={props.icon}
                />
                <CardContent style={{textAlign: 'center'}}>
                    <Typography variant="h1" className={classes.dashCardDescription}>
                        {props.description}
                    </Typography>
                </CardContent>
                <CardActions style={{backgroundColor: '#777', marginTop: '1rem', minHeight: '2rem'}}>
                    <div style={{flexGrow: 1}}></div>
                    {props.secondaryButtonTitle && <Button onClick={() => props.changeScene(props.secondaryScene)} style={{backgroundColor: '#f5f5f5', textTransform: 'none'}} variant="contained" size="small">{props.secondaryButtonTitle}</Button>}
                    {props.primaryButtonTitle && <Button onClick={() => props.changeScene(props.primaryScene)} style={{backgroundColor: '#000', color: '#fff', textTransform: 'none'}} variant="contained" size="small">{props.primaryButtonTitle}</Button>}
                </CardActions>
            </Card>
        </Grid>
    )
}

export default withStyles(CSS)(DashCard);