import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import DashCard from './DashCard';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import WordmarkLight from './../assets/logo-wordmark-horizontal-white.png';
import LogoLight from './../assets/logo-white.png';
import { withStyles } from '@material-ui/core/styles';
import CSS from './../css/Styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import MarketingIcon from '@material-ui/icons/RssFeed';
import CustomersIcon from '@material-ui/icons/People';
import ListIcon from '@material-ui/icons/List';
import MenuIcon from '@material-ui/icons/Menu';
import DropdownIcon from '@material-ui/icons/ArrowDropDownCircle';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Snackbar from '@material-ui/core/Snackbar';
import $ from 'jquery'; 


class Dashboard extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            message: null,
            scene: "log_in",
            timePeriodMenuVisible: false,
            accountMenuVisible: false,
            anchorEl: null,
            companyName: "",
            industry: "",
            contactPerson: "",
            revenueShare: "",
            website: "",
            email: "",
            phone: "",
            zipCode: "",
            password: "",
            confirmPassword: ""
        }

        this.toggleTimePeriodMenuVisibility = this.toggleTimePeriodMenuVisibility.bind(this);
        this.toggleAccountMenuVisibility = this.toggleAccountMenuVisibility.bind(this);
    }

    componentDidMount() {
        $("html, body").scrollTop(0);
    }

    toggleTimePeriodMenuVisibility(event) {
        this.setState({
            timePeriodMenuVisible: !this.state.timePeriodMenuVisible,
            anchorEl: (this.state.timePeriodMenuVisible ? null : event.currentTarget)
        });
    }

    toggleAccountMenuVisibility(event) {
        this.setState({
            accountMenuVisible: !this.state.accountMenuVisible,
            anchorEl: (this.state.accountMenuVisible ? null : event.currentTarget)
        });
    }
    
    changeScene(scene) {
        this.setState({scene});
    }


    render() {
        const { classes } = this.props;
    
        return(
            <React.Fragment>
                <div>
                    <Snackbar
                        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                        open={this.state.message !== null}
                        onClose={() => this.setState({message: null})}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">{this.state.message}</span>}
                    />
                    <AppBar position="relative" className={classes.navigation}>
                        <Grid container style={{overflow: 'hidden', maxWidth: '1600px', alignSelf: 'center'}}>
                            <Grid item md={1}/>
                            <Grid item xs={12} md={10}>
                                <div className={classes.desktop}>
                                    <Toolbar style={{padding: 0, alignItems: 'center'}}>
                                        <img onClick={() => this.changeScene("landing")} className={classes.dashboardLogo} style={{cursor: 'pointer'}} src={LogoLight} alt="eqwity logo"/>
                                        <Typography variant="subtitle1" style={{flexGrow: 1}}>Edgewood Pizza</Typography>
                                        <Typography variant="subtitle2" style={{fontWeight: '700'}}>Time Period:&nbsp;</Typography>
                                        <Typography variant="subtitle2">Q1 2020</Typography>
                                        <IconButton
                                            aria-owns={this.state.timePeriodMenuVisible ? 'time-period-menu' : null}
                                            aria-haspopup="true"
                                            onClick={this.toggleTimePeriodMenuVisibility}
                                            style={{color: '#fff'}}
                                        >
                                            <DropdownIcon/>
                                        </IconButton>
                                        <Menu
                                            id="time-period-menu"
                                            anchorEl={this.state.anchorEl}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={this.state.timePeriodMenuVisible}
                                            onClose={this.toggleTimePeriodMenuVisibility}
                                        >
                                            <MenuItem onClick={() => this.changeTimePeriod('all_time')}>View Total</MenuItem>
                                            <MenuItem onClick={() => this.changeTimePeriod('Q4_2019')}>Q4 2019</MenuItem>
                                            <MenuItem onClick={() => this.changeTimePeriod('Q1_2020')}>Q1 2020</MenuItem>
                                            <MenuItem onClick={() => this.changeTimePeriod('Q2_2020')}>Q2 2020</MenuItem>
                                            <MenuItem onClick={() => this.changeTimePeriod('Q3_2020')}>Q3 2020</MenuItem>
                                            <MenuItem onClick={() => this.changeTimePeriod('Q4_2020')}>Q4 2020</MenuItem>
                                        </Menu>
                                        <IconButton
                                            aria-owns={this.state.accountMenuVisible ? 'account-menu' : null}
                                            aria-haspopup="true"
                                            onClick={this.toggleAccountMenuVisibility}
                                            style={{color: '#fff'}}
                                        >
                                            <AccountCircleIcon/>
                                        </IconButton>
                                        <Menu
                                            id="account-menu"
                                            anchorEl={this.state.anchorEl}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={this.state.accountMenuVisible}
                                            onClose={this.toggleAccountMenuVisibility}
                                        >
                                            <MenuItem onClick={() => this.changeScene('profile')}>Edit Profile</MenuItem>
                                            <MenuItem onClick={() => this.props.logout()}>Log Out</MenuItem>
                                        </Menu>
                                    </Toolbar>

                                </div>
                                <div className={classes.mobile} style={{position: 'relative', textAlign: 'center', padding: '0.5rem'}}>
                                    <img onClick={() => this.changeScene("landing")} className={classes.dashboardLogo} style={{cursor: 'pointer'}} src={LogoLight} alt="eqwity logo"/>
                                    {this.state.scene !== "menu" && <MenuIcon onClick={() => this.changeScene("menu")} style={{zIndex: 999, position: 'absolute', top: '2rem', right: '1rem', fontSize: '2rem', cursor: 'pointer', color: '#777'}}/>}
                                </div>
                            </Grid>
                            <Grid item md={1}/>
                        </Grid>
                    </AppBar>
                    <Grid alignItems="center" container>
                        <DashCard
                            icon={<MoneyIcon className={classes.dashboardIcon}/>}
                            title="Revenue"
                            description="$21,826"
                        />
                        <DashCard
                            icon={<CustomersIcon className={classes.dashboardIcon}/>}
                            title="Customers"
                            description="631"
                        />
                        <DashCard
                            icon={<MarketingIcon className={classes.dashboardIcon}/>}
                            title="Marketing Cost"
                            description="$1,054"
                            primaryButtonTitle="Pay"
                            primaryScene="new_push_notification"
                            secondaryButtonTitle="Set Revenue Share"
                            secondaryScene="sent_push_notifications"
                        />
                        <DashCard
                            icon={<ListIcon className={classes.dashboardIcon}/>}
                            title="Transactions"
                            description="993"
                            primaryButtonTitle="View"
                            primaryScene="new_push_notification"
                            secondaryButtonTitle="Export to CSV"
                            secondaryScene="sent_push_notifications"
                        />
                    </Grid>
                </div>
            </React.Fragment>
        );
    }

}

export default withStyles(CSS)(Dashboard);